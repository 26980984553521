<template>
  <div class="publications">
    <div class="wrap" v-if="windowWidth">
      <div class="thesis" :style="{ minHeight: mainHeight }">
        <div class="screenBox">
          <p class="title">Areas</p>
          <el-checkbox v-model="checkAll" @change="handleCheckAllChange"
            >All Areas<span class="number"> ({{ AllPublications }})</span>
          </el-checkbox>
          <el-checkbox-group
            v-model="checkedCities"
            @change="handleCheckedCitiesChange"
            class="typeCheckBox"
          >
            <el-checkbox
              v-for="item in screens"
              :label="item.screen"
              :key="item.screen"
              >{{ item.screen
              }}<span class="number"> ({{ item.number }})</span></el-checkbox
            >
          </el-checkbox-group>
          <p class="title second">Years</p>
          <el-checkbox v-model="checkYearAll" @change="handleCheckYearAllChange"
            >All Years<span class="number">
              ({{ AllPublications }})</span
            ></el-checkbox
          >
          <el-checkbox-group
            v-model="checkedYearCities"
            @change="handleCheckedCitiesYearChange"
            class="yearCheckBox"
          >
            <el-checkbox
              v-for="item2 in Yearscreens"
              :label="item2.screen"
              :key="item2.screen"
              >{{ item2.screen
              }}<span class="number"> ({{ item2.number }})</span></el-checkbox
            >
          </el-checkbox-group>
        </div>
        <div class="thesis_content" v-show="current == 1">
          <div
            class="paper_box"
            v-for="(item, index) in publicationsData"
            :key="index"
            v-show="
              (item.paper_time == 2020
                ? show2020
                : false || item.paper_time == 2021
                ? show2021
                : false || item.paper_time == 2022
                ? show2022
                : false || item.paper_time == 2023
                ? show2023
                : false || item.paper_time == 2024
                ? show2024
                : false) &&
              (item.paper_classify == 'Vision'
                ? showVision
                : false || item.paper_classify == 'Audio/Speech'
                ? showAudio
                : false || item.paper_classify == 'Graphics'
                ? showGraphics
                : false || item.paper_classify == 'Multimodal'
                ? showMultimodal
                : false || item.paper_classify == 'Information Retrieval'
                ? showInfoRetrieval
                : false || item.paper_classify == 'NLP/NLG'
                ? showNLP
                : false || item.paper_classify == 'Recommendation'
                ? showRec
                : false)
            "
          >
            <div class="paper_info">
              <p class="paper_time">
                {{ item.paper_meeting + " " + item.paper_time }}
              </p>
              <p class="paper_title">
                {{ item.paper_level + item.paper_title }}
              </p>
              <p class="paper_content" @click="ToShow($event)">
                {{ item.paper_content }}
              </p>
              <p class="name">{{ item.paper_authors }}</p>
            </div>
            <a
              class="icon_box"
              v-if="item.paper_pdf != ''"
              :href="item.paper_pdf"
              target="_blank"
            >
              <i class="iconfont icon-PDF1"></i>
              <p>PDF</p>
            </a>
            <a
              class="icon_box"
              :class="item.paper_pdf ? 'icon_box2' : ''"
              v-if="item.paper_git != ''"
              :href="item.paper_git"
              target="_blank"
            >
              <i class="iconfont icon-github1"></i>
              <p>Stars</p>
            </a>
            <div
              class="star_box"
              :class="item.paper_pdf ? '' : 'star_box2'"
              v-if="item.paper_stars != ''"
            >
              <img :src="item.paper_stars" alt="" />
            </div>
            <div class="paper_pic">
              <el-image
                style="width: 100%; height: 100%"
                :src="imglist[index]"
                lazy
                :preview-src-list="imglist"
              >
              </el-image>
            </div>
            <div
              v-if="publicationsData.length == index + 1 ? false : true"
              class="line"
            ></div>
          </div>
        </div>
        <el-backtop
          :right="60"
          :bottom="120"
          :visibility-height="1000"
        ></el-backtop>
        <!-- <div class="pagina">
          <el-pagination
            layout="prev, pager, next"
            :total="13"
            :page-size="5"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div> -->
      </div>
    </div>
    <div class="wrap_mini" v-if="windowWidth == false">
      <van-overlay :show="screensBox" class-name="overlay" />
      <div class="screen_showBox">
        <div class="wxx">
          <div class="control_box" :class="screensBox ? 'srceen_box' : ''">
            <p class="open_btn" @click="openOrClose(screensOpen)">
              筛选<i class="iconfont icon-arrow-down" v-show="!screensBox"></i
              ><i class="iconfont icon-arrow-up" v-show="screensBox"></i>
            </p>
            <div class="choose_Box" v-show="screensBox">
              <p class="title">Areas</p>
              <div class="content">
                <p
                  class="name"
                  :class="Areas ? '' : 'name2'"
                  @click="chooseAreas('Areas', Areas)"
                >
                  All Areas <span>({{ AllPublications }})</span>
                </p>
                <p
                  class="name"
                  :class="!Areas && Vision ? '' : 'name2'"
                  @click="chooseAreas('Vision', Vision)"
                >
                  Vision <span>({{ screens[0].number }})</span>
                </p>
                <p
                  class="name"
                  :class="!Areas && Audio ? '' : 'name2'"
                  @click="chooseAreas('Audio', Audio)"
                >
                  Audio/Speech <span>({{ screens[1].number }})</span>
                </p>
                <p
                  class="name"
                  :class="!Areas && Gra ? '' : 'name2'"
                  @click="chooseAreas('Gra', Gra)"
                >
                  Graphics <span>({{ screens[2].number }})</span>
                </p>
                <p
                  class="name"
                  :class="!Areas && Mul ? '' : 'name2'"
                  @click="chooseAreas('Mul', Mul)"
                >
                  Multimodal <span>({{ screens[3].number }})</span>
                </p>
                <p
                  class="name"
                  :class="!Areas && InfoRe ? '' : 'name2'"
                  @click="chooseAreas('InfoRe', InfoRe)"
                >
                  Information Retrieval <span>({{ screens[4].number }})</span>
                </p>
                <p
                  class="name"
                  :class="!Areas && NLP ? '' : 'name2'"
                  @click="chooseAreas('NLP', NLP)"
                >
                  NLP/NLG <span>({{ screens[5].number }})</span>
                </p>
                <p
                  class="name"
                  :class="!Areas && Rec ? '' : 'name2'"
                  @click="chooseAreas('Rec', Rec)"
                >
                  Recommendation <span>({{ screens[6].number }})</span>
                </p>
              </div>
              <p class="title">Years</p>
              <div class="content">
                <p
                  class="name"
                  :class="Year ? '' : 'name2'"
                  @click="chooseAreas('Year', Year)"
                >
                  All Years <span>({{ AllPublications }})</span>
                </p>
                <p
                  class="name"
                  :class="!Year && Time2024 ? '' : 'name2'"
                  @click="chooseAreas('Time2024', Time2024)"
                >
                  2024
                  <span
                    >({{ Yearscreens[Yearscreens.length - 5].number }})</span
                  >
                </p>
                <p
                  class="name"
                  :class="!Year && Time2023 ? '' : 'name2'"
                  @click="chooseAreas('Time2023', Time2023)"
                >
                  2023
                  <span
                    >({{ Yearscreens[Yearscreens.length - 4].number }})</span
                  >
                </p>
                <p
                  class="name"
                  :class="!Year && Time2022 ? '' : 'name2'"
                  @click="chooseAreas('Time2022', Time2022)"
                >
                  2022
                  <span
                    >({{ Yearscreens[Yearscreens.length - 3].number }})</span
                  >
                </p>
                <p
                  class="name"
                  :class="!Year && Time2021 ? '' : 'name2'"
                  @click="chooseAreas('Time2021', Time2021)"
                >
                  2021
                  <span
                    >({{ Yearscreens[Yearscreens.length - 2].number }})</span
                  >
                </p>
                <p
                  class="name"
                  :class="!Year && Time2020 ? '' : 'name2'"
                  @click="chooseAreas('Time2020', Time2020)"
                >
                  2020
                  <span
                    >({{ Yearscreens[Yearscreens.length - 1].number }})</span
                  >
                </p>
              </div>
              <div class="button_box">
                <p
                  class="reset"
                  :class="reset ? 'reset2' : ''"
                  @click="change('reset')"
                  @touchStart="ButtonTouchStart('reset')"
                  @touchend="change('reset')"
                >
                  重置
                </p>
                <p
                  class="confirm"
                  :class="confirm ? 'confirm2' : ''"
                  @click="change('confirm')"
                  @touchStart="ButtonTouchStart('confirm')"
                  @touchend="change('confirm')"
                >
                  确定
                </p>
              </div>
            </div>
          </div>
          <div class="list_box" v-show="!screensBox">
            <div class="swipe_list" :style="swipe_width">
              <span
                class="swipe"
                @click="openOrClose(screensOpen)"
                v-for="item in screenShowList"
                :key="item"
                >{{ item }}</span
              >
            </div>
            <div class="linear_box"></div>
          </div>
        </div>
      </div>
      <div class="thesis">
        <div class="thesis_content" v-show="current == 1">
          <div
            class="paper_box"
            v-for="(item, index) in publicationsData"
            :key="index"
            v-show="
              (item.paper_time == 2020
                ? show2020_phone
                : false || item.paper_time == 2021
                ? show2021_phone
                : false || item.paper_time == 2022
                ? show2022_phone
                : false || item.paper_time == 2023
                ? show2023_phone
                : false || item.paper_time == 2024
                ? show2024_phone
                : false) &&
              (item.paper_classify == 'Vision'
                ? showVision_phone
                : false || item.paper_classify == 'Audio/Speech'
                ? showAudio_phone
                : false || item.paper_classify == 'Graphics'
                ? showGraphics_phone
                : false || item.paper_classify == 'Multimodal'
                ? showMultimodal_phone
                : false || item.paper_classify == 'Information Retrieval'
                ? showInfoRetrieval_phone
                : false || item.paper_classify == 'NLP/NLG'
                ? showNLP_phone
                : false || item.paper_classify == 'Recommendation'
                ? showRec_phone
                : false)
            "
          >
            <p class="paper_time">
              {{ item.paper_meeting + " " + item.paper_time }}
            </p>
            <p class="paper_title">{{ item.paper_level + item.paper_title }}</p>
            <p class="paper_content" @click="ToShow($event)">
              {{ item.paper_content }}
            </p>
            <p class="name">{{ item.paper_authors }}</p>
            <div class="links_box" v-if="item.paper_pdf || item.paper_git">
              <a
                class="icon_box"
                v-if="item.paper_pdf != ''"
                :href="item.paper_pdf"
                target="_blank"
              >
                <i class="iconfont icon-PDF1"></i>
                <p>PDF</p>
              </a>
              <a
                class="icon_box"
                v-if="item.paper_git != ''"
                :href="item.paper_git"
                target="_blank"
              >
                <i class="iconfont icon-github1"></i>
                <p>Stars</p>
              </a>
              <div
                class="star_box"
                :class="item.paper_pdf ? '' : 'star_box2'"
                v-if="item.paper_stars != ''"
              >
                <img :src="item.paper_stars" alt="" />
              </div>
            </div>
            <div
              v-if="publicationsData.length == index + 1 ? false : true"
              class="line"
            ></div>
          </div>
        </div>
        <!-- <div class="pagina">
          <el-pagination
            layout="prev, pager, next"
            :total="13"
            :page-size="5"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import bus from "../../../assets/js/eventBus";
import publicationsData from "../../../assets/publications.json"; // 引入论文相关信息
export default {
  // 监听页面宽度
  created() {
    window.addEventListener("resize", this.getWidth);
    this.getWidth();
  },
  destroyed() {
    window.removeEventListener("resize", this.getWidth);
  },
  mounted() {
    bus.$emit("sendBybus", this.bannerHeight);
    bus.$emit("textBybus", this.text);
    this.ToNum();
  },
  watch: {
    checkedCities_copy: function (val) {
      // 重置
      this.showVision = false;
      this.showAudio = false;
      this.showGraphics = false;
      this.showMultimodal = false;
      this.showInfoRetrieval = false;
      this.showNLP = false;
      this.showRec = false;
      // 循环数组
      for (let i = 0; i < val.length; i++) {
        if (val[i] == "Vision") {
          this.showVision = true;
        } else if (val[i] == "Audio/Speech") {
          this.showAudio = true;
        } else if (val[i] == "Graphics") {
          this.showGraphics = true;
        } else if (val[i] == "Multimodal") {
          this.showMultimodal = true;
        } else if (val[i] == "Information Retrieval") {
          this.showInfoRetrieval = true;
        } else if (val[i] == "NLP/NLG") {
          this.showNLP = true;
        } else if (val[i] == "Recommendation") {
          this.showRec = true;
        }
      }
    },
    checkedYearCities_copy: function (val) {
      // 重置
      this.show2024 = false;
      this.show2023 = false;
      this.show2022 = false;
      this.show2021 = false;
      this.show2020 = false;
      // 循环数组
      for (let i = 0; i < val.length; i++) {
        if (val[i] == "2022") {
          this.show2022 = true;
        } else if (val[i] == "2021") {
          this.show2021 = true;
        } else if (val[i] == "2020") {
          this.show2020 = true;
        } else if (val[i] == "2023") {
          this.show2023 = true;
        } else if (val[i] == "2024") {
          this.show2024 = true;
        }
      }
    },
    checkedCities: function (val) {
      if (val.length == 0) {
        this.checkAll = true;
        this.checkedCities_copy = [
          "Vision",
          "Audio/Speech",
          "Graphics",
          "Multimodal",
          "Information Retrieval",
          "NLP/NLG",
          "Recommendation",
        ];
      }
    },
    checkedYearCities: function (val) {
      if (val.length == 0) {
        this.checkYearAll = true;
        this.checkedYearCities_copy = ["2024", "2023", "2022", "2021", "2020"];
      }
    },
  },
  data() {
    return {
      bannerHeight: 400,
      text: "学术成果",
      windowWidth: true,

      current: 1,
      imglist: [],
      publicationsData,
      checkAll: true,
      checkedCities: [],
      checkedCities_copy: [
        "Vision",
        "Audio/Speech",
        "Graphics",
        "Multimodal",
        "Information Retrieval",
        "NLP/NLG",
        "Recommendation",
      ],
      screens: [
        { screen: "Vision", number: 0 },
        { screen: "Audio/Speech", number: 0 },
        { screen: "Graphics", number: 0 },
        { screen: "Multimodal", number: 0 },
        { screen: "Information Retrieval", number: 0 },
        { screen: "NLP/NLG", number: 0 },
        { screen: "Recommendation", number: 0 },
      ],
      AllPublications: 0,

      checkYearAll: true,
      checkedYearCities: [],
      checkedYearCities_copy: ["2024", "2023", "2022", "2021", "2020"],
      Yearscreens: [
        { screen: "2024", number: 0 },
        { screen: "2023", number: 0 },
        { screen: "2022", number: 0 },
        { screen: "2021", number: 0 },
        { screen: "2020", number: 0 },
      ],
      // 控制论文是否展示
      showVision: true,
      showAudio: true,
      showGraphics: true,
      showMultimodal: true,
      showInfoRetrieval: true,
      showNLP: true,
      showRec: true,
      show2024: true,
      show2023: true,
      show2022: true,
      show2021: true,
      show2020: true,

      screensOpen: false,
      screensBox: false,
      screenShowList: ["All Areas", "All Years"],
      swipe_width: "width:3.82rem",
      reset: false,
      confirm: false,
      Areas: true,
      Vision: false,
      Audio: false,
      Gra: false,
      Mul: false,
      InfoRe: false,
      NLP: false,
      Rec: false,
      Year: true,
      Time2024: false,
      Time2023: false,
      Time2022: false,
      Time2021: false,
      Time2020: false,
      showVision_phone: true,
      showAudio_phone: true,
      showGraphics_phone: true,
      showMultimodal_phone: true,
      showInfoRetrieval_phone: true,
      showNLP_phone: true,
      showRec_phone: true,
      show2024_phone: true,
      show2023_phone: true,
      show2022_phone: true,
      show2021_phone: true,
      show2020_phone: true,
      mainHeight: "600px",
    };
  },
  methods: {
    // 获取页面宽度
    getWidth() {
      if (window.innerWidth > 1032) {
        this.windowWidth = true;
      } else {
        this.windowWidth = false;
      }
      // 控制body的最小高度,防止footer没有在最下方
      if (window.innerHeight > 1130) {
        let h = window.innerHeight - 530;
        this.mainHeight = h + "px";
      } else {
        this.mainHeight = "600px";
      }
    },
    // 文章计数
    ToNum() {
      let that = this;
      let arr = this.publicationsData;
      // 初始化
      that.imglist = [];
      that.screens = [
        { screen: "Vision", number: 0 },
        { screen: "Audio/Speech", number: 0 },
        { screen: "Graphics", number: 0 },
        { screen: "Multimodal", number: 0 },
        { screen: "Information Retrieval", number: 0 },
        { screen: "NLP/NLG", number: 0 },
        { screen: "Recommendation", number: 0 },
      ];
      that.Yearscreens = [
        { screen: "2024", number: 0 },
        { screen: "2023", number: 0 },
        { screen: "2022", number: 0 },
        { screen: "2021", number: 0 },
        { screen: "2020", number: 0 },
      ];
      let len = that.Yearscreens.length;
      that.AllPublications = arr.length;
      for (let i = 0; i < arr.length; i++) {
        that.imglist.push(arr[i].imgUrl);
        switch (arr[i].paper_classify) {
          case "Vision":
            that.screens[0].number++;
            break;
          case "Audio/Speech":
            that.screens[1].number++;
            break;
          case "Graphics":
            that.screens[2].number++;
            break;
          case "Multimodal":
            that.screens[3].number++;
            break;
          case "Information Retrieval":
            that.screens[4].number++;
            break;
          case "NLP/NLG":
            that.screens[5].number++;
            break;
          case "Recommendation":
            that.screens[6].number++;
            break;

          default:
            break;
        }
        switch (arr[i].paper_time) {
          case 2024:
            this.Yearscreens[len - 5].number++;
            break;
          case 2023:
            this.Yearscreens[len - 4].number++;
            break;
          case 2022:
            this.Yearscreens[len - 3].number++;
            break;
          case 2021:
            this.Yearscreens[len - 2].number++;
            break;
          case 2020:
            this.Yearscreens[len - 1].number++;
            break;

          default:
            break;
        }
      }
    },
    // 文章收缩
    ToShow(e) {
      if (getComputedStyle(e.currentTarget).display == "-webkit-box") {
        e.currentTarget.style.display = "block";
      } else if (getComputedStyle(e.currentTarget).display == "block") {
        e.currentTarget.style.display = "-webkit-box";
      }
    },
    handleCheckAllChange(val) {
      this.checkedCities = [];
      if (val) {
        this.checkedCities_copy = [
          "Vision",
          "Audio/Speech",
          "Graphics",
          "Multimodal",
          "Information Retrieval",
          "NLP/NLG",
          "Recommendation",
        ];
      } else {
        this.checkedCities_copy = [];
      }
    },
    handleCheckedCitiesChange(value) {
      if (value.length > 0) {
        this.checkedCities_copy = value;
        this.checkAll = false;
      } else {
        this.checkedCities_copy = [
          "Vision",
          "Audio/Speech",
          "Graphics",
          "Multimodal",
          "Information Retrieval",
          "NLP/NLG",
          "Recommendation",
        ];
        this.checkAll = true;
      }
    },
    handleCheckYearAllChange(val) {
      this.checkedYearCities = [];
      if (val) {
        this.checkedYearCities_copy = ["2024", "2023", "2022", "2021", "2020"];
      } else {
        this.checkedYearCities_copy = [];
      }
    },
    handleCheckedCitiesYearChange(value) {
      if (value.length > 0) {
        this.checkedYearCities_copy = value;
        this.checkYearAll = false;
      } else {
        this.checkedYearCities_copy = ["2024", "2023", "2022", "2021", "2020"];
        this.checkYearAll = true;
      }
    },
    openOrClose(val) {
      this.Areas = false;
      this.Vision = false;
      this.Audio = false;
      this.Gra = false;
      this.Mul = false;
      this.InfoRe = false;
      this.NLP = false;
      this.Rec = false;
      this.Year = false;
      this.Time2024 = false;
      this.Time2023 = false;
      this.Time2022 = false;
      this.Time2021 = false;
      this.Time2020 = false;
      for (let i = 0; i < this.screenShowList.length; i++) {
        switch (this.screenShowList[i]) {
          case "All Areas":
            this.Areas = true;
            this.Vision = false;
            this.Audio = false;
            this.Gra = false;
            this.Mul = false;
            this.InfoRe = false;
            this.NLP = false;
            this.Rec = false;
            break;
          case "Vision":
            this.Vision = true;
            break;
          case "Audio/Speech":
            this.Audio = true;
            break;
          case "Graphics":
            this.Gra = true;
            break;
          case "Multimodal":
            this.Mul = true;
            break;
          case "Information Retrieval":
            this.InfoRe = true;
            break;
          case "NLP/NLG":
            this.NLP = true;
            break;
          case "Recommendation":
            this.Rec = true;
            break;
          case "All Years":
            this.Year = true;
            this.Time2024 = false;
            this.Time2023 = false;
            this.Time2022 = false;
            this.Time2021 = false;
            this.Time2020 = false;
            break;
          case "2024":
            this.Time2024 = true;
            break;
          case "2023":
            this.Time2023 = true;
            break;
          case "2022":
            this.Time2022 = true;
            break;
          case "2021":
            this.Time2021 = true;
            break;
          case "2020":
            this.Time2020 = true;
            break;

          default:
            break;
        }
      }
      if (val) {
        this.screensBox = false;
      } else {
        this.screensBox = true;
        this.reset = false;
        this.confirm = false;
      }
      this.screensOpen = !val;
    },
    change(val) {
      if (val == "reset") {
        this.reset = true;
        this.screenShowList = ["All Areas", "All Years"];
        this.swipe_width = "width:3.98rem";
        this.showVision_phone = true;
        this.showAudio_phone = true;
        this.showGraphics_phone = true;
        this.showMultimodal_phone = true;
        this.showInfoRetrieval_phone = true;
        this.showNLP_phone = true;
        this.showRec_phone = true;
        this.show2024_phone = true;
        this.show2023_phone = true;
        this.show2022_phone = true;
        this.show2021_phone = true;
        this.show2020_phone = true;
      } else if (val == "confirm") {
        let num = 0;
        this.confirm = true;
        this.screenShowList = [];
        this.showVision_phone = false;
        this.showAudio_phone = false;
        this.showGraphics_phone = false;
        this.showMultimodal_phone = false;
        this.showInfoRetrieval_phone = false;
        this.showNLP_phone = false;
        this.showRec_phone = false;
        this.show2024_phone = false;
        this.show2023_phone = false;
        this.show2022_phone = false;
        this.show2021_phone = false;
        this.show2020_phone = false;
        if (this.Areas) {
          this.screenShowList.push("All Areas");
          num += 1.55;
          this.showVision_phone = true;
          this.showAudio_phone = true;
          this.showGraphics_phone = true;
          this.showMultimodal_phone = true;
          this.showInfoRetrieval_phone = true;
          this.showNLP_phone = true;
          this.showRec_phone = true;
        } else {
          if (this.Vision) {
            this.screenShowList.push("Vision");
            num += 1.2;
            this.showVision_phone = true;
          }
          if (this.Audio) {
            this.screenShowList.push("Audio/Speech");
            num += 2.14;
            this.showAudio_phone = true;
          }
          if (this.Gra) {
            this.screenShowList.push("Graphics");
            num += 1.65;
            this.showGraphics_phone = true;
          }
          if (this.Mul) {
            this.screenShowList.push("Multimodal");
            num += 1.85;
            this.showMultimodal_phone = true;
          }
          if (this.InfoRe) {
            this.screenShowList.push("Information Retrieval");
            num += 3.15;
            this.showInfoRetrieval_phone = true;
          }
          if (this.NLP) {
            this.screenShowList.push("NLP/NLG");
            num += 1.85;
            this.showNLP_phone = true;
          }
          if (this.Rec) {
            this.screenShowList.push("Recommendation");
            num += 2.14;
            this.showRec_phone = true;
          }
        }
        if (this.Year) {
          this.screenShowList.push("All Years");
          num += 1.43;
          this.show2024_phone = true;
          this.show2023_phone = true;
          this.show2022_phone = true;
          this.show2021_phone = true;
          this.show2020_phone = true;
        } else {
          if (this.Time2024) {
            this.screenShowList.push("2024");
            num += 1.14;
            this.show2024_phone = true;
          }
          if (this.Time2023) {
            this.screenShowList.push("2023");
            num += 1.14;
            this.show2023_phone = true;
          }
          if (this.Time2022) {
            this.screenShowList.push("2022");
            num += 1.14;
            this.show2022_phone = true;
          }
          if (this.Time2021) {
            this.screenShowList.push("2021");
            num += 1.14;
            this.show2021_phone = true;
          }
          if (this.Time2020) {
            this.screenShowList.push("2020");
            num += 1.14;
            this.show2020_phone = true;
          }
        }
        this.swipe_width = "width:" + num + "rem";
      }
      setTimeout(() => {
        this.openOrClose(true);
      }, 100);
    },
    chooseAreas(from, val) {
      switch (from) {
        case "Areas":
          if (val) {
            this.Areas = val;
          } else {
            this.Areas = !val;
          }
          this.Vision = false;
          this.Audio = false;
          this.Gra = false;
          this.Mul = false;
          this.InfoRe = false;
          this.NLP = false;
          this.Rec = false;
          break;
        case "Vision":
          this.Vision = !val;
          if (
            val &&
            !this.Audio &&
            !this.Gra &&
            !this.Mul &&
            !this.InfoRe &&
            !this.NLP &&
            !this.Rec
          ) {
            this.Areas = true;
          } else {
            this.Areas = false;
          }
          break;
        case "Audio":
          this.Audio = !val;
          if (
            val &&
            !this.Vision &&
            !this.Gra &&
            !this.Mul &&
            !this.InfoRe &&
            !this.NLP &&
            !this.Rec
          ) {
            this.Areas = true;
          } else {
            this.Areas = false;
          }
          break;
        case "Gra":
          this.Gra = !val;
          if (
            val &&
            !this.Vision &&
            !this.Audio &&
            !this.Mul &&
            !this.InfoRe &&
            !this.NLP &&
            !this.Rec
          ) {
            this.Areas = true;
          } else {
            this.Areas = false;
          }
          break;
        case "Mul":
          this.Mul = !val;
          if (
            val &&
            !this.Vision &&
            !this.Audio &&
            !this.Gra &&
            !this.InfoRe &&
            !this.NLP &&
            !this.Rec
          ) {
            this.Areas = true;
          } else {
            this.Areas = false;
          }
          break;
        case "InfoRe":
          this.InfoRe = !val;
          if (
            val &&
            !this.Vision &&
            !this.Audio &&
            !this.Gra &&
            !this.Mul &&
            !this.NLP &&
            !this.Rec
          ) {
            this.Areas = true;
          } else {
            this.Areas = false;
          }
          break;
        case "NLP":
          this.NLP = !val;
          if (
            val &&
            !this.Vision &&
            !this.Audio &&
            !this.Gra &&
            !this.Mul &&
            !this.InfoRe &&
            !this.Rec
          ) {
            this.Areas = true;
          } else {
            this.Areas = false;
          }
          break;
        case "Rec":
          this.Rec = !val;
          if (
            val &&
            !this.Vision &&
            !this.Audio &&
            !this.Gra &&
            !this.Mul &&
            !this.InfoRe &&
            !this.NLP
          ) {
            this.Areas = true;
          } else {
            this.Areas = false;
          }
          break;
        case "Year":
          if (val) {
            this.Year = val;
          } else {
            this.Year = !val;
          }
          this.Time2024 = false;
          this.Time2023 = false;
          this.Time2022 = false;
          this.Time2021 = false;
          this.Time2020 = false;
          break;
        case "Time2024":
          this.Time2024 = !val;
          if (
            val &&
            !this.Time2021 &&
            !this.Time2020 &&
            !this.Time2022 &&
            !this.Time2023
          ) {
            this.Year = true;
          } else {
            this.Year = false;
          }
          break;
        case "Time2023":
          this.Time2023 = !val;
          if (
            val &&
            !this.Time2021 &&
            !this.Time2020 &&
            !this.Time2022 &&
            !this.Time2024
          ) {
            this.Year = true;
          } else {
            this.Year = false;
          }
          break;
        case "Time2022":
          this.Time2022 = !val;
          if (
            val &&
            !this.Time2021 &&
            !this.Time2020 &&
            !this.Time2023 &&
            !this.Time2024
          ) {
            this.Year = true;
          } else {
            this.Year = false;
          }
          break;
        case "Time2021":
          this.Time2021 = !val;
          if (
            val &&
            !this.Time2022 &&
            !this.Time2020 &&
            !this.Time2023 &&
            !this.Time2024
          ) {
            this.Year = true;
          } else {
            this.Year = false;
          }
          break;
        case "Time2020":
          this.Time2020 = !val;
          if (
            val &&
            !this.Time2022 &&
            !this.Time2021 &&
            !this.Time2023 &&
            !this.Time2024
          ) {
            this.Year = true;
          } else {
            this.Year = false;
          }
          break;
        default:
          break;
      }
    },
    ButtonTouchStart(val) {
      if (val == "reset") {
        this.reset = true;
      } else if (val == "confirm") {
        this.confirm = true;
      }
    },
  },
};
</script>
<style lang="less">
.publications {
  width: 100%;
  font-size: 0.16rem;

  .wrap {
    max-width: 1200px;
    margin: 0 auto;

    .thesis {
      margin-top: 0.3rem;
      display: flex;
      justify-content: left;

      .tit {
        height: 0.5rem;
        line-height: 0.5rem;
        font-family: PingFangSC-Semibold, sans-serif;
        font-size: 0.36rem;
        color: #222222;
        text-align: center;
      }

      .screenBox {
        width: 2.03rem;
        height: 6.64rem;
        margin-right: 0.24rem;
        background: #f8fafc;
        position: -webkit-sticky;
        /* Safari */
        position: sticky;
        top: 0.8rem;

        .title {
          box-sizing: border-box;
          width: 2.03rem;
          height: 0.48rem;
          background: #eff2f7;
          line-height: 0.48rem;
          font-family: PingFang SC;
          font-style: normal;
          font-weight: 600;
          font-size: 0.16rem;
          color: #222222;
          padding-left: 0.1rem;
          margin-bottom: 0.08rem;
        }

        .second {
          margin-top: 0.16rem;
        }

        .typeCheckBox {
          max-height: 2.4rem;
          overflow-y: scroll;
        }
        .typeCheckBox::-webkit-scrollbar {
          width: 0;
          height: 0;
          display: none;
        }
        .yearCheckBox{
          max-height: 2rem;
          overflow-y: scroll;
        }
        .yearCheckBox::-webkit-scrollbar {
          width: 0;
          height: 0;
          display: none;
        }

        .el-checkbox {
          width: 100%;
          height: 0.4rem;
          box-sizing: border-box;
          padding-left: 0.1rem;
          line-height: 0.4rem;
          font-family: PingFang SC;
          font-weight: 400;

          .el-checkbox__input.is-checked + .el-checkbox__label,
          .el-checkbox__label {
            color: #222222;
          }

          .el-checkbox__input.is-checked .el-checkbox__inner,
          .el-checkbox__input.is-indeterminate .el-checkbox__inner {
            background-color: #2e54eb;
            border-color: #2e54eb;
          }

          .el-checkbox__label {
            line-height: 0.22rem;
          }

          .el-checkbox__input .el-checkbox__inner {
            width: 0.16rem;
            height: 0.16rem;
          }

          .el-checkbox__inner:hover {
            border: 1px solid #2e54eb;
          }

          .el-checkbox__inner::after {
            top: 0.02rem;
            left: 0.05rem;
          }

          .number {
            color: #999;
          }
        }

        .el-checkbox:hover {
          background: #f2f6fa;
        }
      }

      .thesis_content {
        width: 9.73rem;
        padding-bottom: 0.5rem;
        min-height: 6rem;

        .paper_box {
          position: relative;
          overflow: auto;

          .paper_info {
            width: 6.67rem;
          }

          .paper_time {
            font-family: PingFangSC-Regular, sans-serif;
            font-size: 0.16rem;
            color: #2d8cf0;
            font-weight: 600;
            line-height: 0.32rem;
          }

          .paper_title {
            font-family: PingFangSC-Semibold, sans-serif;
            font-size: 0.28rem;
            color: #4a4a4a;
            line-height: 0.4rem;
            margin: 0.1rem 0;
            font-weight: 600;
          }

          .paper_content {
            line-height: 0.32rem;
            font-family: PingFangSC-Regular, sans-serif;
            font-size: 0.16rem;
            color: #666666;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            padding-right: 0.2rem;
            cursor: pointer;
          }

          .name {
            margin-top: 0.2rem;
            line-height: 0.32rem;
            font-family: PingFangSC-Regular, sans-serif;
            font-size: 0.14rem;
            color: #666;
            font-weight: 600;
          }

          .icon_box {
            position: absolute;
            left: 6.83rem;
            top: 0.1rem;
            text-decoration: none;
            text-align: center;
            width: 0.42rem;
            border-radius: 0.05rem;

            .icon-PDF1,
            .icon-github1 {
              font-size: 0.36rem;
              color: #1462bb;
              line-height: 0.36rem;
            }

            p {
              text-align: center;
              font-size: 0.14rem;
              color: #1462bb;
              line-height: 1.2;
              font-weight: 600;
            }
          }

          .icon_box:hover {
            background: #0a4283;

            .icon-PDF1,
            .icon-github1,
            p {
              color: #fff;
            }
          }

          .star_box {
            width: 0.88rem;
            height: 0.2rem;
            position: absolute;
            left: 7.75rem;
            top: 0.46rem;
            overflow: hidden;

            img {
              height: 0.2rem;
              position: absolute;
              left: -0.56rem;
              top: 0rem;
            }
          }

          .icon_box2 {
            left: 7.33rem;
          }

          .star_box2 {
            left: 7.25rem;
          }

          .active {
            object-fit: contain;
          }

          .paper_pic {
            position: absolute;
            left: 6.83rem;
            top: 0.7rem;
            width: 2.7rem;
            height: 1.6rem;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }

        .line {
          width: 100%;
          height: 1px;
          background: #e5e5e5;
          border-radius: 0.06rem;
          margin: 0.26rem 0 0.2rem;
        }
      }
    }
  }

  .el-pagination {
    margin-top: 0.3rem;
    margin-bottom: 0.5rem;
  }

  .wrap_mini {
    max-width: 750px;
    margin: 0 auto;
    position: relative;

    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .screen_showBox {
      width: 100%;
      height: 1.04rem;
      position: -webkit-sticky;
      position: sticky;
      top: 1.41rem;
      z-index: 100;
      background: #fff;

      .wxx {
        width: 100%;
        min-height: 1.04rem;
        position: absolute;
        top: 0;
        left: 0;

        .control_box {
          position: absolute;
          width: 1.62rem;
          right: 0;
          top: 0;
          background: #fff;

          .open_btn {
            position: absolute;
            top: 0.35rem;
            right: 0.4rem;
            width: 0.92rem;
            height: 0.34rem;
            font-family: "PingFang SC";
            font-style: normal;
            font-weight: 500;
            font-size: 0.26rem;
            line-height: 0.34rem;
            color: #222222;

            i {
              font-size: 0.24rem;
              margin-left: 0.16rem;
              color: #242424;
            }
          }
        }

        .srceen_box {
          width: 7.5rem;
          min-height: 6.99rem;
          box-sizing: border-box;
          padding: 0.35rem 0.4rem;

          .choose_Box {
            width: 6.7rem;

            .title {
              width: 100%;
              height: 0.34rem;
              font-family: "PingFang SC";
              font-style: normal;
              font-weight: 500;
              font-size: 0.26rem;
              line-height: 0.34rem;
              color: #222222;
            }

            .content {
              width: 100%;
              display: flex;
              justify-content: left;
              flex-wrap: wrap;
              margin-bottom: 0.4rem;

              .name {
                padding: 0.12rem 0.16rem;
                margin-top: 0.24rem;
                margin-right: 0.24rem;
                background: #ecf3ff;
                font-family: "PingFang SC";
                font-style: normal;
                font-weight: 400;
                font-size: 0.24rem;
                line-height: 0.32rem;
                color: #002acf;
                border-radius: 0.04rem;

                span {
                  color: #002acf;
                }
              }

              .name2 {
                color: #666;
                background: #f3f5f8;

                span {
                  color: #999;
                }
              }
            }

            .button_box {
              display: flex;
              justify-content: space-between;

              p {
                width: 3.15rem;
                height: 0.8rem;
                line-height: 0.8rem;
                text-align: center;
                font-family: "PingFang SC";
                font-style: normal;
                font-weight: 400;
                font-size: 0.28rem;
                border-radius: 0.05rem;
              }

              .reset {
                border: 1px solid #999999;
                color: #222222;
              }

              .reset2 {
                border: 1px solid #2e54eb;
                color: #2e54eb;
              }

              .confirm {
                background: #2e54eb;
                color: #fff;
              }

              .confirm2 {
                background: #002acf;
              }
            }
          }
        }

        .list_box {
          width: 5.88rem;
          height: 1.04rem;
          box-sizing: border-box;
          padding: 0.24rem 0 0.24rem 0.4rem;
          overflow-y: hidden;
          overflow-x: scroll;

          .swipe_list {
            min-width: 100%;
            height: 100%;

            .swipe {
              height: 0.56rem;
              font-family: "PingFang SC";
              font-style: normal;
              font-weight: 400;
              font-size: 0.24rem;
              margin-right: 0.24rem;
              background: #ecf3ff;
              border-radius: 0.04rem;
              text-align: center;
              line-height: 0.56rem;
              padding: 0.12rem 0.16rem;
              color: #2e54eb;
            }
          }

          .linear_box {
            width: 0.32rem;
            height: 100%;
            background: linear-gradient(
              272.24deg,
              #ffffff 2.35%,
              rgba(255, 255, 255, 0) 98.61%
            );
            position: absolute;
            right: 1.62rem;
            top: 0;
          }
        }

        .list_box::-webkit-scrollbar {
          display: none;
        }

        .list_box {
          -ms-overflow-style: none;
        }

        .list_box {
          overflow: -moz-scrollbars-none;
        }
      }
    }

    .thesis {
      padding: 0rem 0.4rem 0.8rem;
      min-height: 7rem;

      .thesis_content {
        .paper_box {
          position: relative;

          .paper_time {
            font-family: PingFangSC-Regular, sans-serif;
            font-size: 0.2rem;
            color: #2d8cf0;
            font-weight: 600;
            line-height: 0.4rem;
          }

          .paper_title {
            font-family: PingFangSC-Semibold, sans-serif;
            font-size: 0.4rem;
            color: #4a4a4a;
            line-height: 0.56rem;
            margin: 0.16rem 0 0.3rem;
            font-weight: 600;
          }

          .paper_content {
            line-height: 0.4rem;
            font-family: PingFangSC-Regular, sans-serif;
            font-size: 0.2rem;
            color: #666666;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            overflow: hidden;
            cursor: pointer;
          }

          .name {
            margin-top: 0.3rem;
            line-height: 0.4rem;
            font-family: PingFangSC-Regular, sans-serif;
            font-size: 0.2rem;
            color: #666;
            font-weight: 600;
          }
        }

        .links_box {
          margin-top: 0.2rem;
          position: relative;

          .icon_box {
            display: inline-block;
            text-align: center;
            margin-right: 0.2rem;
            width: 0.56rem;
            border-radius: 0.05rem;

            .icon-PDF1,
            .icon-github1 {
              font-size: 0.4rem;
              color: #1462bb;
              line-height: 0.4rem;
            }

            p {
              text-align: center;
              font-size: 0.14rem;
              color: #1462bb;
              line-height: 1.2;
              font-weight: 600;
            }
          }

          .star_box {
            width: 1.1rem;
            height: 0.25rem;
            position: absolute;
            left: 1.32rem;
            top: 0.38rem;
            overflow: hidden;

            img {
              height: 0.25rem;
              position: absolute;
              left: -0.7rem;
              top: 0rem;
            }
          }

          .star_box2 {
            left: 0.56rem;
          }
        }

        .line {
          width: 100%;
          height: 1px;
          background: #e5e5e5;
          border-radius: 0.06rem;
          margin: 0.4rem 0;
        }
      }
    }
  }
}
</style>